import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Logo } from './Logo';

interface NavItemProps {
  name: string;
  path: string;
  className?: string;
}

const navItems: NavItemProps[] = [];

const NavItem = ({ name, path, className }: NavItemProps) => (
  <a
    href={path}
    className={`text-gray-600 hover:text-gray-900 transition-colors ${className}`}
  >
    {name}
  </a>
);

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-sm sticky top-0 z-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-center items-center h-16">
          <Logo />

          <div className="hidden md:flex items-center space-x-8">
            {navItems.map(item => (
              <NavItem key={item.name} {...item} />
            ))}
          </div>

          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900"
            aria-label={isOpen ? 'Close menu' : 'Open menu'}
          >
            {isOpen ? <X /> : <Menu />}
          </button>
        </div>

        {isOpen && (
          <div className="md:hidden py-4">
            {navItems.map(item => (
              <NavItem 
                key={item.name} 
                {...item} 
                className="block py-2"
              />
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};
