import React from 'react';
import { EXCHANGES } from '../constants';
import type { Exchange } from '../types';

interface ExchangeCardProps extends Exchange {
  name: string;
  url: string;
  description: string;
  discount: string;
  badge: string;
}

const ExchangeCard = ({ name, url, description, discount, badge }: ExchangeCardProps) => (
  <a
    href={url}
    className="group bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition-all duration-300 relative overflow-hidden"
  >
    <div className="absolute top-4 right-4 bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
      {badge}
    </div>
    <h3 className="text-lg font-bold text-gray-800 group-hover:text-orange-500 transition-colors">
      {name}
    </h3>
    <p className="text-gray-600 mt-2">{description}</p>
    <div className="mt-4 inline-block bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm">
      {discount}
    </div>
  </a>
);

export const ExchangeLinks = () => (
  <div className="grid md:grid-cols-2 gap-6 mt-6">
    {EXCHANGES.map((exchange: Exchange) => (
      <ExchangeCard key={exchange.name} {...exchange} />
    ))}
  </div>
);
