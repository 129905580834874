import React, { useState, useCallback, useEffect } from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';
import { Navbar } from './Navbar';
import { PriceChart } from './PriceChart';
import { ExchangeLinks } from './ExchangeLinks';
import { CONSTANTS } from '../constants';

const formatCurrency = (value: number, currency: keyof typeof CONSTANTS.CURRENCIES) => 
  new Intl.NumberFormat(CONSTANTS.CURRENCIES[currency].locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0
  }).format(value);

const BitcoinPriceTracker = () => {
  const [prices, setPrices] = useState<{ usd: number | null; pln: number | null }>({ usd: null, pln: null });
  const [priceChanges, setPriceChanges] = useState<{ usd: number | null; pln: number | null }>({ usd: null, pln: null });
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const fetchPrice = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${CONSTANTS.COINGECKO_API_URL}?ids=bitcoin&vs_currencies=usd,pln&include_24hr_change=true`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      const currentPrice = data.bitcoin.usd;
      setPrices({
        usd: currentPrice,
        pln: data.bitcoin.pln
      });
      
      setPriceChanges({
        usd: data.bitcoin.usd_24h_change,
        pln: data.bitcoin.pln_24h_change
      });
      setLastUpdate(new Date().toLocaleTimeString());
    } catch (err) {
      console.error('Error fetching price:', err);
      setError('Unable to fetch price data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPrice();
    const interval = setInterval(fetchPrice, CONSTANTS.REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [fetchPrice]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <main className="max-w-6xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl shadow-lg p-6 space-y-4">
          <div className="flex flex-col items-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Kurs Bitcoina</h1>
          </div>
          
          <div className="bg-gray-50 rounded-lg p-6">
            {error ? (
              <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-sm">
                    <div className="text-sm font-medium text-gray-500 mb-2">Kurs USD</div>
                    <div className="text-5xl font-bold text-gray-900 mb-3">
                      {prices.usd ? formatCurrency(prices.usd, 'USD') : '---'}
                    </div>
                    {priceChanges.usd && (
                      <div className={`flex items-center gap-2 text-lg font-semibold ${
                        priceChanges.usd >= 0 ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {priceChanges.usd >= 0 ? (
                          <TrendingUp className="w-6 h-6" />
                        ) : (
                          <TrendingDown className="w-6 h-6" />
                        )}
                        <span>
                          {priceChanges.usd >= 0 ? '+' : ''}{priceChanges.usd.toFixed(2)}%
                        </span>
                      </div>
                    )}
                  </div>
                  
                  <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-sm">
                    <div className="text-sm font-medium text-gray-500 mb-2">Kurs PLN</div>
                    <div className="text-5xl font-bold text-gray-900 mb-3">
                      {prices.pln ? formatCurrency(prices.pln, 'PLN') : '---'}
                    </div>
                    {priceChanges.pln && (
                      <div className={`flex items-center gap-2 text-lg font-semibold ${
                        priceChanges.pln >= 0 ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {priceChanges.pln >= 0 ? (
                          <TrendingUp className="w-6 h-6" />
                        ) : (
                          <TrendingDown className="w-6 h-6" />
                        )}
                        <span>
                          {priceChanges.pln >= 0 ? '+' : ''}{priceChanges.pln.toFixed(2)}%
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="flex justify-between items-center text-sm text-gray-500">
            <div className="flex items-center gap-2">
              <div className={`w-2 h-2 rounded-full ${loading ? 'bg-yellow-500' : 'bg-green-500'}`} />
              <span>{loading ? 'Aktualizacja...' : 'Kurs na żywo'}</span>
            </div>
            <div>
              Ostatnia aktualizacja: {lastUpdate || '---'}
            </div>
          </div>
        </div>

        <PriceChart />
        
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Polecane Giełdy Kryptowalut</h2>
          <ExchangeLinks />
        </div>
      </main>
    </div>
  );
};

export default BitcoinPriceTracker;
