export const CONSTANTS = {
  REFRESH_INTERVAL: 60000,
  CHART_HEIGHT: 256,
  HOURS_TO_SHOW: 24,
  BINANCE_API_URL: 'https://api.binance.com/api/v3/klines',
  COINGECKO_API_URL: 'https://api.coingecko.com/api/v3/simple/price',
  CURRENCIES: {
    USD: { label: 'USD', locale: 'en-US' },
    PLN: { label: 'PLN', locale: 'pl-PL' }
  }
} as const;

export const EXCHANGES = [
  { 
    name: 'Binance', 
    url: 'https://accounts.binance.com/register?ref=TQO9C0QR', 
    description: 'Największa giełda kryptowalut na świecie', 
    discount: '20% zniżki na opłaty',
    badge: 'Popularna'
  },
  { 
    name: 'Robinhood', 
    url: 'https://join.robinhood.com/eu_crypto/filipk-3629cf7/', 
    description: 'Najłatwiejsza dla początkujących', 
    discount: '10 EUR bonus przy wpłacie 10 EUR',
    badge: 'Najprostsza'
  },
] as const;
