import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { CONSTANTS } from '../constants';
import { useHistoricalPriceData } from '../hooks/useHistoricalPriceData';
import type { PriceDataPoint } from '../types';

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload as PriceDataPoint;
    return (
      <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
        <p className="text-sm text-gray-600">{data.time}</p>
        <p className="text-sm font-semibold text-gray-800">
          ${Number(data.price).toFixed(2)}
        </p>
      </div>
    );
  }
  return null;
};

const PriceChartComponent: React.FC = () => {
  const { data, error, isLoading, refetch } = useHistoricalPriceData();

  if (error) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6 mt-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Wykres kursu z ostatnich 24 godzin
        </h2>
        <div className="h-64 flex items-center justify-center">
          <div className="text-red-500 flex flex-col items-center">
            <span>{error}</span>
            <button
              onClick={refetch}
              className="mt-4 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
            >
              Spróbuj ponownie
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading || data.length === 0) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6 mt-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">
          Wykres kursu z ostatnich 24 godzin
        </h2>
        <div className="h-64 flex items-center justify-center">
          <div className="text-gray-500 flex items-center">
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-orange-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            Ładowanie danych...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 mt-6">
      <h2 className="text-xl font-bold text-gray-800 mb-4">
        Wykres kursu z ostatnich 24 godzin
      </h2>
      <div className="h-64">
        <ResponsiveContainer width="100%" height={CONSTANTS.CHART_HEIGHT}>
          <LineChart data={data}>
            <XAxis
              dataKey="time"
              stroke="#9CA3AF"
              fontSize={12}
              tickLine={false}
              interval="preserveStartEnd"
            />
            <YAxis
              stroke="#9CA3AF"
              fontSize={12}
              tickLine={false}
              tickFormatter={(value) => `$${(value / 1000).toFixed(1)}k`}
              domain={['auto', 'auto']}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="price"
              stroke="#F59E0B"
              strokeWidth={2}
              dot={false}
              animationDuration={300}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export const PriceChart = React.memo(PriceChartComponent);
