import { useState, useEffect } from 'react';
import { CONSTANTS } from '../constants';
import type { PriceDataPoint } from '../types';

const formatTime = (timestamp: number): string => {
  return new Date(timestamp).toLocaleString('pl-PL', {
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const useHistoricalPriceData = () => {
  const [data, setData] = useState<PriceDataPoint[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const endTime = Date.now();
      const startTime = endTime - (24 * 60 * 60 * 1000); // Exactly 24 hours ago

      const params = new URLSearchParams({
        symbol: 'BTCUSDT',
        interval: '1h',
        limit: '24',
        startTime: startTime.toString(),
        endTime: endTime.toString(),
      });

      const response = await fetch(`${CONSTANTS.BINANCE_API_URL}?${params}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const rawData = await response.json();
      
      if (!Array.isArray(rawData) || rawData.length === 0) {
        throw new Error('Invalid data format received');
      }

      const processedData = rawData.map((item: any[]) => ({
        timestamp: item[0],
        time: formatTime(item[0]),
        price: parseFloat(item[4]), // Close price
      }));

      setData(processedData);
      setError(null);
    } catch (err) {
      console.error('Failed to fetch historical data:', err);
      setError('Nie udało się pobrać danych historycznych');
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, error, isLoading, refetch: fetchData };
};
