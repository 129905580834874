import React from 'react';

export const Logo = () => (
  <div className="flex items-center">
    <div className="w-10 h-10 bg-orange-500 rounded-full flex items-center justify-center">
      <span className="text-white text-xl font-bold">₿</span>
    </div>
    <span className="ml-2 text-xl font-bold text-gray-800">bitcoinkurs.pl</span>
  </div>
);
